import axios from 'axios';
// import qs from 'qs';
// import { Toast } from 'vant';
import router from 'vue-router';
import store from '@/store/index';
// import Cookies from 'js-cookie';
// import {errlog, infolog} from '@/utils/log.js'
const tokenURL = process.env.VUE_APP_JAVA;
const statebase = store.state.base;
let isRefreshing = false; // 是否正在刷新
let loadingRef = false; // 是否正在刷新

const requests = []; // 存储请求的数组

// 挂起请求
function subscribeTokenRefresh(cb) {
  requests.push(cb);
}
// 执行挂起的请求（数组中的请求得到新的token之后自执行，用新的token去请求数据）
function onRrefreshed(token) {
  requests.map((cb) => cb(token));
}

const Ajax = axios.create({
  baseURL: tokenURL,
  // timeout: 1000 * 10,
  timeout: 1500 * 10 //test
  // 上传进度回调
  // onUploadProgress: function (progressEvent) {},
  // 下载进度回调
  // onDownloadProgress: function (progressEvent) {}
});

// 接口报错，容错处理
const errorHandler = (err = {}) => {
  console.log('err111: ', err, 11, err.request.path, 22, err.message, 33, err.status, 44, new Date());
  window.Communication('closeLoading', '3'); // 通知App 打开关闭loading
  try {
    const mttime = dayjs();
    const msg = `{"Function":"JS中的axios异常","Url":"${err.config.url}","params":"${err.config.params}","CountryCode":"${
      err.config.headers.CountryCode
    }","LanguageCode":"${err.config.headers.LanguageCode}","BasicData":"${err.config.headers.BasicData}","HttpType":"${err.config.method}","HttpState":${
      err.response.status
    },"Time":${timer},"Parameter":${JSON.stringify(err.config.params)},"Message":"${err.message}","WikiRequestId":"${mttime.format('YYYYMMDDHHmmssSSS')}${(
      Math.random() * 899999
    )
      .toString()
      .replace('.', '')}"}`;
    // infolog(msg, err.config.url);
    console.log(msg, err.config.url);
  } catch (err) {}
};
// 添加一个请求拦截器
Ajax.interceptors.request.use(async (config) => {
  loadingRef = false;

  const bfp = localStorage.getItem('wiki-bfp') ? localStorage.getItem('wiki-bfp') : '822ca7b3261a0b2ec10c8cdabfb5a0ea';
  let base = `999,0,2,999,0,${bfp},0`;
  if (statebase.isWiki == '1') {
    base = `999,0,2,999,0,${bfp},0`;
  } else if (statebase.myCountry == 'cn') {
    base = `999,0,1,225,1,${bfp},1`;
  } else {
    base = `999,0,2,999,0,${bfp},0`;
  }
  Object.assign(config.headers, {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    CountryCode: statebase.CountryCode || '156',
    'x-forword-for': statebase.userIp,
    LanguageCode: statebase.LanguageCode,
    BasicData: base,
    ENVIRONMENT: statebase.nodeEnv,
    metadata: {
      starttime: new Date().getTime()
    }
  });
  const [now, cookieTime] = [Date.parse(new Date()), localStorage.getItem('expiresIn')]; // 获取当前时间戳和token有效时长
  // if (process?.server) return config
  if (typeof window === 'undefined') {
    // 服务器端逻辑
    return config;
  }
  // 客户端请求
  const au = `fx${statebase.userIp.split('.').join('')}`;
  const token = localStorage.getItem(au);
  if (token && now < cookieTime) {
    // 存在token且尚未失效时
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    // 不存在token时
    if (!isRefreshing) {
      try {
        isRefreshing = true; // 正在刷新中
        const authorizeData = await axios
          .post(`${tokenURL}/oauth2/authorize`, {
            // 1. 获取授权码
            response_type: 'code',
            scope: 'email address',
            client_id: 'YWdncmVnYXRpb24tYXV0aGVudGljYXRpb24taWQ=',
            provision_key: 'wiki-aggregation-layer-key',
            authenticated_userid: 'authenticated_tester'
          })
          .catch((err) => {
            console.log(err);
          });
        // isRefreshing = true // 正在刷新中
        const tokenData = await axios
          .post(`${tokenURL}/oauth2/token`, {
            // 2. 获取访问码
            grant_type: 'authorization_code',
            client_id: 'YWdncmVnYXRpb24tYXV0aGVudGljYXRpb24taWQ=',
            client_secret: 'YWdncmVnYXRpb24tYXV0aGVudGljYXRpb24tc2VjcmV0',
            code: authorizeData.data.redirect_uri.split('=')[1]
          })
          .catch((err) => {
            console.error('2025年3月25日17:41:55--------------------------------tokenData--err')
            store.commit('base/set_is404', true);
          });

        isRefreshing = false;
        const { access_token: ntoken = '', expires_in: expiresIn = '' } = tokenData.data || {};
        // console.log(ntoken, 88812)
        const in1hour = expiresIn * 1000 + Date.parse(new Date()); // 有效时长
        localStorage.setItem(au, ntoken, {
          maxAge: 600,
          domain: statebase.cookieHost,
          path: '/'
        }); // 存储token
        localStorage.setItem('expiresIn', in1hour, {
          maxAge: 600,
          domain: statebase.cookieHost,
          path: '/'
        }); // 存储token有效时长
        config.headers.Authorization = `Bearer ${ntoken}`;
        onRrefreshed(ntoken);
        config.headers.Authorization = `Bearer ${ntoken}`;
        return config;
      } catch (error) {
        console.log(error);
        console.error('2025年3月25日17:41:55--------------------------------刷新--err')
        store.commit('base/set_is404', true);
      }
    }
    const retry = new Promise((resolve) => {
      subscribeTokenRefresh((accessToken) => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(config);
      });
    });
    return retry;
    // config.headers.Authorization = `Bearer ${ntoken}`
    // return config
  }
}, errorHandler);

// 添加一个响应拦截器
Ajax.interceptors.response.use(
  (res) => {
    // console.log('res: ', res);
    // res.config.headers.metadata.endtime = new Date().getTime()
    const timer = new Date().getTime() - res.config.headers.metadata.starttime;
    if (timer >= 3000) {
      //=>改至15秒
      const param = {
        url: res.config.url, // 请求地址
        method: res.config.method, // 请求方式
        statusCode: res.status, // 请求状态
        params: res.config.params, // 请求参数
        timer, // 请求时间
        level: 'warn', // 报错等级info-严重；warn-警告
        type: 'url', // url接口-asyncData-asyncData js报错
        message: '接口超时', // 报错内容
        CountryCode: res.config.headers.CountryCode, // header国家
        LanguageCode: res.config.headers.LanguageCode, // headers语言
        BasicData: res.config.headers.BasicData, // header---BasicData
        requestid: res.headers['wikidatacenter-request-id'], // 唯一标识id
        time: new Date(),
        Routes: res?.config?.headers?.Routes || res?.config?.headers?.Route
      };
      // errlog(param);
      console.log(param);
    }

    // 如果为invoke-v1调用接口 则返回外层data 其他则解构内层data
    return res?.data?.data || res?.data;
  },
  (err) => {
    if (err?.response?.status == 401) {
      const au = `fx${statebase.userIp.split('.').join('')}`;
      localStorage.removeItem(au);
    }
    // window.Communication('closeLoading','5') // 通知App 打开关闭loading
    err.response && requestErrorHander(err.response.status);
    return Promise.reject(err);
  }
);

function requestErrorHander(code) {
  let err = {
    code
  };
  switch (code) {
    case 400:
      err.message = '请求错误';
      break;
    case 401:
      err.message = '未授权，请重新登录，2 秒后将跳转至登录页面';
      localStorage.clear();
      router.replace({ name: 'login' });
      break;
    case 403:
      err.message = '拒绝访问';
      break;
    case 404:
      err.message = '请求接口地址出错';
      break;
    case 408:
      err.message = '请求超时';
      break;
    case 500:
      err.message = '服务器内部错误';
      break;
    case 501:
      err.message = '服务未实现';
      break;
    case 502:
      err.message = '网关错误';
      break;
    case 503:
      err.message = '服务不可用';
      break;
    case 504:
      err.message = '网关超时';
      break;
    case 505:
      err.message = 'HTTP版本不受支持';
      break;
    default:
  }
  //   Toast.fail(`${err.message}(${code})`);
  return err;
}

export default Ajax;
